<!--短信设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">短信设置</span>
      </div>
      <div>

      </div>
    </div>

    <el-card shadow="never" class="margintop">
      <el-form ref="form" :model="form" label-width="300px">
        <el-checkbox v-model="checked">全选</el-checkbox>
        <div class="shudaodiv">
          <span class="shudao"></span>
          <span class="shudaowenzi">语音通知</span>
        </div>
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="预约超时"></el-checkbox>
            <el-checkbox label="受理超时"></el-checkbox>
            <el-checkbox label="派单超时"></el-checkbox>
            <el-checkbox label="接单超时"></el-checkbox>
            <el-checkbox label="跟进超时"></el-checkbox>
            <el-checkbox label="到达超时"></el-checkbox>
            <el-checkbox label="完成超时"></el-checkbox>
            <el-checkbox label="回访超时"></el-checkbox>
            <el-checkbox label="审核超时"></el-checkbox>
            <el-checkbox label="严重超时"></el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="shudaodiv">
          <span class="shudao"></span>
          <span class="shudaowenzi">选择提醒人员</span>
        </div>
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="调度人员"></el-checkbox>
            <el-checkbox label="接单服务商"></el-checkbox>
            <el-checkbox label="接单技师"></el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="shudaodiv">
          <span class="shudao"></span>
          <span class="shudaowenzi">发送短信</span>
        </div>
        <el-row :gutter="15">
          <el-col :span="8">
            <el-checkbox v-model="checked1">自动发送车主查单链接</el-checkbox>
            <div class="margintop1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="车主查单短信内容"
                  v-model="form.textarea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <el-checkbox v-model="checked2">自动发送车主查单链接</el-checkbox>
            <div class="margintop1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="H5技师接单短信内容"
                  v-model="form.textarea">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <el-checkbox v-model="checked3">自动发送服务商联系人通知有订单</el-checkbox>
            <div class="margintop1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="服务商联系人通知短信内容"
                  v-model="form.textarea">
              </el-input>
            </div>
          </el-col>
        </el-row>

      </el-form>
    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        v-auths="[`${$config.uniquePrefix}operate:smsSettings:put`]"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
import {get_note_config, Saveeset_note_config} from "@/api/operate";

export default {
  data(){
    return{
      form:{},

      List:[],

      // 全选
      checked: false,

      checkList:[],

      checked1:false,
      checked2:false,
      checked3:false

    }
  },

  mounted() {
    this.getConfig();
  },

  methods:{
    //获取
    getConfig(){
      get_note_config().then(res => {
        console.log(res);
        this.List = res.data;
      })
    },

    //点击全选
    handleCheckAllChange(){
      if(this.checked === true){
        this.List.forEach(item => {
          item.data.forEach(res => {
            res.is_switch = true;
          })
        })
      }else {
        this.List.forEach(item => {
          item.data.forEach(res => {
            res.is_switch = false;
          })
        })
      }
    },

    //保存
    save(){
      let where = {
        data: this.List
      }
      Saveeset_note_config(where).then(res => {
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getConfig();
        }else {
          this.$message.error(e.msg)
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

  },

}
</script>

<style lang="scss" scoped>
  .margintop{
    margin-top: 20px;
  }
  .shudao{
    width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;
  }
  .shudaowenzi{
    font-size: 20px;font-weight: 700;color: #050505;
  }
  .shudaodiv{
    display: flex;align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .margintop1{
    margin-top: 10px;
  }
</style>
